
import Alert from "@/components/Alert.vue";
import QrCode from "@/components/QrCode.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Card from "../components/Card.vue";
import { Customer } from "@/sinnoAppApi/Customer";
import { Company } from "@/sinnoAppApi/Company";

@Component({
    components: {
        Card: Card,
        Alert: Alert,
        QrCode: QrCode
    }
})
export default class InstallInstructions extends Vue {
    @Prop({required: true})
    public readonly customer!: Customer;

    @Prop({required: true})
    public readonly company!: Company;

    public get appStartUri(): string {
        let retValue;
        switch (this.customer.companyId) {
            case "385266":
                retValue = 'neusehland';
                break;
            case "445595":
                retValue = 'hurlinapp';
                break;
            case "487148":
                retValue = 'kuellingapp';
                break;
            case "501767":
                retValue = 'grevingapp';
                break;
            case "542957":
                retValue = 'schwarzoptik';
                break;
            case "720920":
                retValue = 'flueckigerapp';
                break;
            case "721414":
                retValue = 'milleroptik';
                break;
            default:
                retValue = 'sinnoapp';
        }

        return retValue + `://start/${this.customer.companyId}`;
    }

    public get checkIsSiNNOApp() : boolean {
        return (this.company.DeepLinkBaseURL == null || this.company.DeepLinkBaseURL == 'sinnoapp://')
    }

    // TODO: should come from the api!
    public googlePlayStoreUrl(): string {
        const clones = [];
        clones[385266] = 'de.ipro.sinnoclone.neusehland';
        clones[445595] = 'de.ipro.sinnoclone.hurlin';
        clones[487148] = 'de.ipro.sinnoclone.kuelling';
        clones[501767] = 'de.ipro.sinnoclone.greving';
        clones[542957] = 'de.ipro.sinnoclone.schwarzOptik';
        clones[720920] = 'de.ipro.sinnoclone.flueckiger';
        clones[721414] = 'de.ipro.sinnoclone.miller';

        return clones[parseInt(this.customer.companyId)] ?? 'de.ipro.sinnoapp';
    }

    // TODO: should come from the api!
    public appleAppStoreUrl(): string {
        const clones = [];
        clones[385266] = 'neusehland-die-kunden-app/id1608213368';
        clones[445595] = 'hurlin/id1608213397';
        clones[487148] = 'külling-die-kunden-app/id1630369869';
        clones[501767] = 'greving-app/id6508167865';
        clones[542957] = 'schwarz-optik-die-kunden-app/id6473427324';
        clones[720920] = 'flückiger-optik-hörcenter/id6502614456';
        clones[721414] = 'my-miller/id6502614221';

        return clones[parseInt(this.customer.companyId)] ?? 'sinno-die-kunden-app/id1579520974';
    }
}
